import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/core/service/auth.service";
import { GraphqlService } from "src/app/core/service/graphql.service";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { UserData } from "src/app/core/models/users";
import { SharedService } from "src/app/shared/shared.service";
import { A } from "@angular/cdk/keycodes";
import { log } from "console";
import { MutationsRouteService } from "src/app/core/service/mutations-route.service";

@Component({
  selector: "app-add-form",
  templateUrl: "./add-form.component.html",
  styleUrls: ["./add-form.component.sass"],
})
export class AddFormComponent implements OnInit {
  dialogTitle: string;
  requestForm: FormGroup;
  user: UserData;
  reqId: string;
  taskId: string;
  assignedTime: number;
  dueDate: Date | null;
  remainCount: number;
  currentExtensionLimit: number;
  length: number;
  isAlreadyRequested: boolean = false;
  userLimit: number;
  approvedRequest = [];
  pendingRequests = [];
  isPendingRequestExists: boolean = false;
  taskName: string;
  constructor(
    public dialogRef: MatDialogRef<AddFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reqId: string;
      assignedTime: number | null;
      taskId: string;
      taskTitle: string;
      dueDate: Date | null;
      remainCount: number;
    },
    private graphql: GraphqlService,
    private auth: AuthService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private shared: SharedService,
    public mutationService: MutationsRouteService
  ) {
    this.getData(data);
  }

  ngOnInit(): void {
    this.getUser();
    this.dialogTitle = " ";
    this.getTaskExtensionLimit();
    this.checkIfAlreadyRequested();
    this.createLeaveForm();
  }

  getData(data: {
    reqId: string;
    assignedTime: number | null;
    taskId: string;
    taskTitle:string;
    dueDate: Date | null;
    remainCount: number;
  }) {
    this.taskId = data.taskId;
    this.reqId = data.reqId;
    this.assignedTime = data.assignedTime;
    this.dueDate = data.dueDate;
    this.remainCount = data.remainCount;
    this.taskName = data.taskTitle;
  }

  createLeaveForm() {
    if (this.user.dbRole === "admin")
      this.requestForm = this.fb.group({
        response: [null],
        extension: [0],
        extensionDate: [null],
      });
    else if (
      this.user.dbRole === "employee" ||
      this.user.dbRole === "project-manager"
    )
      this.requestForm = this.fb.group({
        task_id: [this.taskId],
        created_by: [this.user.external_id],
        // response_from: [""],
        request: ["", [Validators.required]],
        response: [null],
        available_limits: [""],
        task_name: this.taskName,
        // status: ["", [Validators.required]],
      });
  }

  getUser() {
    this.user = this.auth.userData();
    // console.log(this.user);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTaskExtensionLimit() {
    this.graphql.getUser(this.user.external_id).subscribe(({ data }) => {
      this.currentExtensionLimit = data.task_manager_users[0].extension_limit;
    });
  }

  checkIfAlreadyRequested() {
    const requestParams = {
      where: {
        created_by: {
          _eq: this.user.external_id,
        },
        task_id: { _eq: this.taskId },
        // status: { _in: ["approved"] },
      },
    };
    console.log(requestParams);
    this.graphql.getTaskRequests(requestParams).subscribe({
      next: (res) => {
        this.approvedRequest = res.data.filter(
          (item) => item.status == "approved"
        );
        this.pendingRequests = res.data.filter(
          (item) => item.status == "pending"
        );
        if (this.pendingRequests.length > 0) {
          this.isPendingRequestExists = true;
          this.requestForm.setErrors({ invalid: true });
        }
        this.length = this.approvedRequest.length;
        console.log(this.length); // length of Data  ie, row entries
        if (this.length == 0) {
          this.isAlreadyRequested = false;

          let limits: number = this.currentExtensionLimit;
          // console.log(`$ limits is ${limits},currentExtensionLimit is ${this.currentExtensionLimit}`);
          this.userLimit = limits;
          //console.log(`$ userLimits is ${this.userLimit} `);
        } else {
          console.log(this.length);
          this.isAlreadyRequested = true;
          let limits: number = this.currentExtensionLimit - this.length;

          if (limits < 0) {
            this.userLimit = 0;
          } else if (limits >= 0) {
            this.userLimit = limits;
          }
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  submit() {
    if (this.isAlreadyRequested == false) {
      // length = 0
      console.log(this.isAlreadyRequested);
      delete this.requestForm.value.response;
      this.requestForm.value.available_limits = this.userLimit; //getting from db ie, fixed
      const extensionData = { object: this.requestForm.value };
      console.log(" userlimit is fixed , ", this.userLimit);
      this.mutationService
        .mutations("extensionReq", "dummy-table-name", extensionData, this.user)
        .subscribe({
          next: (res) => {
            console.log(res);
          },
          error: (err) => {
            console.error("Error Fetching request counts :", err);
          },
        });
    } else if (this.isAlreadyRequested == true) {
      // true for requested users
      let limits: number = this.currentExtensionLimit - this.length;
      // console.log(limits);
      let available_requests = limits;
      delete this.requestForm.value.response;
      this.requestForm.value.available_limits = this.userLimit;
      console.log("userlimit ", this.userLimit);
      console.log("available_requests is  ", available_requests);
      const data = { object: this.requestForm.value };
      this.mutationService
        .mutations("extensionReq", "dummy-table-name", data, this.user)
        .subscribe({
          next: (res) => {
            console.log(res);
            available_requests = limits -= 1;
          },
          error: (err) => {
            this.dialogRef.disableClose = true;
            console.error("Error Fetching request counts :", err);
          },
        });
    }
  }

  // submit() {
  //   if (!this.isAlreadyRequested) {
  //     let limits: number = this.currentExtensionLimit;
  //     let available_requests = limits;
  //     console.log("ava", available_requests);
  //     delete this.requestForm.value.response;
  //     const data = { object: this.requestForm.value };
  //     this.graphql.createTaskRequest(data).subscribe(({ data }) => {
  //       available_requests = this.currentExtensionLimit -= 1;
  //       console.log("request", available_requests);
  //       // localStorage.removeItem("taskId");
  //       const log = `Time Extension Request`;
  //       this.shared.addLog(log, this.user.external_id, this.taskId);
  //     });
  //   } else if (this.isAlreadyRequested) {
  //     let limits: number = this.currentExtensionLimit - this.length;
  //     let available_requests = limits;
  //     console.log("ava", available_requests);
  //     delete this.requestForm.value.response;
  //     const data = { object: this.requestForm.value };
  //     this.graphql.createTaskRequest(data).subscribe(({ data }) => {
  //       available_requests = limits -= 1;
  //       console.log("request", available_requests);
  //       // localStorage.removeItem("taskId");
  //       const log = `Time Extension Request`;
  //       this.shared.addLog(log, this.user.external_id, this.taskId);
  //     });
  //   }
  // }

  approve() {
    if (!this.reqId) {
      this.shared.showNotification(
        "snackbar-danger",
        "Not Updated...!!!",
        "bottom",
        "center"
      );
      return;
    }

    const queryParams = {
      where: {
        id: {
          _eq: this.reqId,
        },
      },
      _set: {
        status: "approved",
        response: this.requestForm.value.response,
        response_from: this.user.external_id,
        extra_time: this.requestForm.value.extension,
        available_limits: this.userLimit - 1,
        ...(this.requestForm.value.extensionDate && {
          extension_date: this.requestForm.value.extensionDate,
        }),
      },
    };
    this.graphql.updateTaskRequest(queryParams).subscribe(({ data }) => {
      const requesterId =
        data.update_task_manager_task_requests.returning[0].requester
          .external_id;

      this.shared.sendNotification(
        this.user.external_id,
        "Time Extension: Approved",
        "Your extension request for the task has been approved. Please login to Kingpin to continue working on it.",
        "task",
        this.taskId,
        [requesterId]
      );

      const log = `Time Extension: Approved`;
      this.shared.addLog(log, this.user.external_id, this.taskId);
      this.updateTask();
    });
  }

  updateTask() {
    const newTime = this.assignedTime + this.requestForm.value.extension;
    const queryParams = {
      where: {
        id: {
          _eq: this.taskId,
        },
      },
      _set: {
        assigned_time: newTime,
        ...(this.requestForm.value.extensionDate && {
          due_date: this.requestForm.value.extensionDate,
        }),
      },
    };

    this.graphql.updateTask(queryParams, {}, {}).subscribe((data) => {});
  }

  reject() {
    if (!this.reqId && !this.user.external_id) {
      this.shared.showNotification(
        "snackbar-danger",
        "Rejection Failed...!!!",
        "bottom",
        "center"
      );
      return;
    }
    const queryParams = {
      where: {
        id: {
          _eq: this.reqId,
        },
      },
      _set: {
        status: "rejected",
        response: this.requestForm.value.response,
        response_from: this.user.external_id,
      },
    };
    this.graphql.updateTaskRequest(queryParams).subscribe(({ data }) => {
      const requesterId =
        data.update_task_manager_task_requests.returning[0].requester
          .external_id;
      this.shared.sendNotification(
        this.user.external_id,
        "Time Extension: Rejected",
        "Sorry to inform you that your time extension request has been rejected",
        "task",
        this.taskId,
        [requesterId]
      );

      const log = `Time Extension: Rejected`;
      this.shared.addLog(log, this.user.external_id, this.taskId);
    });
  }
}
